import config from 'common/config'

const postUrl = (post: BlogPost) => `${config.siteUrl}${post.fields.slug}`

export const facebookShareLink = (post: BlogPost) =>
  `https://www.facebook.com/sharer/sharer.php?u=${postUrl(post)}`

export const twitterShareLink = (post: BlogPost) =>
  `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    `${post.frontmatter.title} by ${post.frontmatter.author.fullName} ${postUrl(
      post
    )}`
  )}`
