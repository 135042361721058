import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import Categories from 'components/categories'
import BlogPostSEO from 'components/seo/blog-post'
import FacebookIcon from 'components/icons/facebook'
import TwitterIcon from 'components/icons/twitter'
import ScrollToTopContainer from 'components/scroll-to-top'
import { facebookShareLink, twitterShareLink } from './share-links'
import urls from 'common/urls'
import { ButtonLink } from 'components/button'
import Author from './author'
import {
  Wrapper,
  Inner,
  Title,
  Category,
  ContentWrapper,
  Content,
  HeroImage,
  Info,
  Dot,
  SideBar,
  ShareIcons,
  ShareLink,
  Subtitle,
} from './styled'

type Props = {
  data: {
    post: BlogPost
    recommendedPosts: {
      nodes: readonly BlogPost[]
    }
    allCategoriesJson: {
      nodes: readonly BlogCategory[]
    }
  }
}

const BlogPost = ({ data }: Props) => {
  const {
    title,
    category,
    thumbnailLarge,
    author,
    date,
  } = data.post.frontmatter
  const categories = data.allCategoriesJson.nodes

  return (
    <Layout withHeaderBorder>
      <BlogPostSEO post={data.post} />
      <Categories categories={categories} current={category.slug} />
      <Wrapper>
        <Inner topPadding>
          <Category>{category.title}</Category>
          <Title>{title}</Title>
          <Info>
            By {author.fullName} <Dot>・</Dot> {date}
          </Info>
          {/* @ts-expect-error */}
          <HeroImage fluid={thumbnailLarge.childImageSharp.fluid} critical />
        </Inner>
      </Wrapper>

      <ScrollToTopContainer topSpacing="large">
        <Wrapper>
          <Inner bottomPadding>
            <ContentWrapper>
              <Content
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: data.post.html,
                }}
              />
              <SideBar>
                <ShareIcons>
                  <ShareLink
                    href={facebookShareLink(data.post)}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Share on Facebook"
                  >
                    <FacebookIcon />
                  </ShareLink>
                  <ShareLink
                    href={twitterShareLink(data.post)}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Share on Twitter"
                  >
                    <TwitterIcon />
                  </ShareLink>
                </ShareIcons>
              </SideBar>
            </ContentWrapper>
            <Author author={author} />
            <Subtitle>
              Learn more about<span translate="no"> Fellow&apos;s </span>convenient, private,
              and accurate mail-in tests.
            </Subtitle>
            <ButtonLink to={urls.shop}>Learn More</ButtonLink>
          </Inner>
        </Wrapper>
        {/* <RecommendedArticles articles={data.recommendedPosts.nodes} /> */}
      </ScrollToTopContainer>
    </Layout>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $categorySlug: String!) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      ...postFields
    }
    allCategoriesJson {
      nodes {
        ...categoryFields
      }
    }
    recommendedPosts: allMarkdownRemark(
      limit: 5
      filter: {
        fields: { slug: { ne: $slug } }
        frontmatter: { category: { slug: { eq: $categorySlug } } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        ...postFields
      }
    }
  }
`
