import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import theme from 'styles/theme'
import mq from 'styles/mq'
import { heading, font } from 'styles/typography'

export const Wrapper = styled.section`
  background: ${theme.color.background.light};
`

export const Inner = styled.main<{
  topPadding?: boolean
  bottomPadding?: boolean
}>`
  max-width: 108rem;
  margin: 0 auto;
  text-align: center;

  ${props =>
    props.topPadding &&
    css`
      padding-top: 13rem;
    `}

  ${props =>
    props.bottomPadding &&
    css`
      padding-bottom: 10rem;

      ${mq.medium} {
        padding-bottom: 12.4rem;
      }
    `}
`

export const Title = styled.h1`
  ${heading.xl};
`

export const Subtitle = styled.h4`
  padding: 30px;
  margin-top: 20px;
`

export const Category = styled.div`
  ${heading.black};
  margin-bottom: 1.4rem;
`

export const Info = styled.div`
  ${heading.black};
  text-transform: unset;
  letter-spacing: 0.02em;
  margin-bottom: 14.2rem;

  ${mq.medium} {
    margin-bottom: 7.2rem;
  }
`

export const Dot = styled.span`
  display: block;
  opacity: 0;
  font-size: 3rem;
  line-height: 0.3;

  ${mq.medium} {
    color: ${theme.color.accent.secondary};
    display: inline-block;
    vertical-align: middle;
    margin-top: 0.5rem;
    line-height: 0;
    opacity: 1;
  }
`

export const HeroImage = styled(Img)`
  max-width: 100%;
`

export const Content = styled.div`
  margin: 0 3rem;
  max-width: 71.3rem;
  text-align: left;

  ${mq.medium} {
    margin: 0 auto 7.2rem;
    grid-column: 2;
  }

  img,
  iframe,
  .gatsby-resp-image-figure {
    display: block;
    margin: 6.4rem auto;
    max-width: 100%;
  }

  figcaption {
    text-align: center;
  }

  p {
    line-height: 1.5;
    margin: 0 0 2.5rem;
  }

  blockquote {
    font-size: 3.2rem;
    margin: 6.4rem 0;

    p {
      &:before {
        content: '“';
      }

      &:after {
        content: '”';
      }
    }
  }

  > p:first-of-type ::first-letter {
    display: block;
    float: left;
    font-size: 3.6rem;
    padding: 1rem;
    border: 0.1rem solid ${theme.color.border.light};
    font-weight: bold;
    font-family: ${font.headings};
    margin: 0.8rem 1rem 0 0;
    line-height: 1;
  }
`

export const ContentWrapper = styled.div`
  display: grid;
  padding-top: 5rem;

  ${mq.medium} {
    grid-template-columns: 6.5rem 1fr 6.5rem;
  }
`

export const SideBar = styled.div`
  grid-row: 1;

  ${mq.medium} {
    grid-column: 3;
  }
`

export const ShareIcons = styled.div`
  border: 0.1rem solid ${theme.color.border.light};
  display: inline-flex;
  margin-bottom: 3rem;

  ${mq.medium} {
    display: block;
    position: sticky;
    top: 17rem;
    margin-bottom: 10rem;
  }
`

export const ShareLink = styled.a`
  width: 5rem;
  height: 5rem;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${theme.color.background.gray};
  }

  &:not(:last-child) {
    border-right: 0.1rem solid ${theme.color.border.light};
  }

  svg {
    transform: scale(0.6);
  }

  ${mq.medium} {
    width: 6.3rem;
    height: 6.3rem;

    &:not(:last-child) {
      border-right: 0;
      border-bottom: 0.1rem solid ${theme.color.border.light};
    }

    svg {
      transform: scale(0.8);
    }
  }
`
