import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import theme from 'styles/theme'
import mq from 'styles/mq'
import { heading } from 'styles/typography'

export const Wrapper = styled.div`
  max-width: 53rem;
  border: 0.1rem solid ${theme.color.border.blue};
  padding: 2.4rem;
  display: grid;
  grid-template-columns: 1fr 7.5rem;
  text-align: left;
  align-items: center;
  margin: 0 3rem;

  ${mq.medium} {
    grid-template-columns: 1fr 10rem;
    margin: 0 auto;
    padding: 4rem;
  }
`

export const Content = styled.div`
  padding-right: 2.4rem;

  ${mq.medium} {
    padding-right: 4rem;
  }
`

export const Avatar = styled(Img)`
  border-radius: 50%;
  width: 7.5rem;
  height: 7.5rem;
  object-fit: cover;

  ${mq.medium} {
    width: 10rem;
    height: 10rem;
  }
`

export const Name = styled(Link)`
  ${heading.xs};
  display: block;
  margin-bottom: 1.2rem;
`

export const Details = styled.p`
  display: none;

  ${mq.medium} {
    display: unset;
  }
`
