import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { FluidObject } from 'gatsby-image'
import SEO from 'components/seo'
import config from 'common/config'

const query = graphql`
  query {
    logo: file(relativePath: { eq: "fellow.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 512) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

type QueryResult = {
  logo: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

type Props = {
  post: BlogPost
}

const BlogPostSEO = ({ post }: Props) => {
  const result = useStaticQuery<QueryResult>(query)

  const siteUrl = config.siteUrl
  const title = post.frontmatter.title
  const author = post.frontmatter.author.fullName
  const description = post.excerpt
  const thumbnail = String(post.frontmatter.thumbnail.childImageSharp.fluid.src)
  const thumbnailUrl = `${siteUrl}${thumbnail}`
  const postUrl = `${siteUrl}${post.fields.slug}`
  const logoUrl = `${siteUrl}${result.logo.childImageSharp.fluid.src}`

  return (
    <SEO
      title={title}
      description={description}
      type="article"
      image={thumbnailUrl}
      url={postUrl}
      structuredData={[
        {
          '@context': 'http://schema.org',
          '@type': 'BlogPosting',
          url: postUrl,
          name: title,
          alternateName: title,
          headline: title,
          image: {
            '@type': 'ImageObject',
            url: thumbnailUrl,
          },
          description,
          author: {
            '@type': 'Person',
            name: author,
          },
          publisher: {
            '@type': 'Organization',
            url: siteUrl,
            logo: {
              '@type': 'ImageObject',
              url: logoUrl,
            },
            name: author,
          },
          datePublished: post.frontmatter.date,
          dateModified: post.frontmatter.date,
          mainEntityOfPage: {
            '@type': 'WebSite',
            '@id': siteUrl,
          },
        },
      ]}
    />
  )
}

export default BlogPostSEO
