import React from 'react'
import { blogAuthorUrl } from 'common/urls'
import { Wrapper, Avatar, Name, Content, Details } from './styled'

type Props = {
  author: Author
}

const Author = ({ author }: Props) => {
  return (
    <Wrapper>
      <Content>
        <Name to={blogAuthorUrl(author.slug)}>{author.fullName}</Name>
        <Details dangerouslySetInnerHTML={{ __html: author.details }}></Details>
      </Content>
      {/* @ts-expect-error */}
      <Avatar fluid={(author.thumbnail as FluidImage).childImageSharp.fluid} />
    </Wrapper>
  )
}

export default Author
